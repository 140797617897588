import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const state = {
};

const NoMbrState = (set, get) => ({
  ...state,
  setCommonData(key, value) {
    set((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  }
});

const NoMbrStore = create(createStore(NoMbrState));

export default NoMbrStore;
