import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const defaultLanguage = 'jp';
const state = {
  isMobile: true,
  language: defaultLanguage,
  isLogin: false,
  userInfo: {},
  adminInfo: {},
  loginTimeCount: 600,
  timeoutModal: false,
  interval: null,
  errMsg: {
    error: '',
    message: ''
  },
  errState: false,
  goLoginPage: false,
  signImg: {
    isSigned: false,
    imgFile: {}
  }
};

const commonStore = (set) => ({
  ...state,
  setMobile(value) {
    set(() => ({
      isMobile: value
    }));
  },
  setIsLogin(value) {
    set(() => ({
      isLogin: value
    }));
  },
  setUserInfo(value) {
    set((state) => {
      return {
        userInfo: {
          ...state.userInfo,
          ...value
        }
      };
    });
  },
  setAdminInfo(value) {
    set((state) => {
      return {
        adminInfo: {
          ...state.adminInfo,
          ...value
        }
      };
    });
  },
  setLogOut() {
    localStorage.removeItem(process.env.VUE_APP_LOCALSTORAGE_KEY);
    set(() => ({
      isLogin: false
    }));
  },
  setCommonData(key, value) {
    set(() => {
      return {
        [key]: value
      };
    });
  },
  setErrorData(value) {
    set(() => {
      return {
        errMsg: value
      };
    });
  },
  setModalControl(key, value, modalType) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value
        }
      };
    });
  },
  setSignImg(key, value) {
    set((state) => {
      return {
        signImg: {
          ...state.signImg,
          [key]: value
        }
      };
    });
  }
});

export default create(createStore(commonStore));
