import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const mainState = {
  storageKey: 'llpjoin',
  currentUuid: '',
  isOisDo: false,
  isLlpDo: false,
  selectUserCate: '',
  oisAgreeSeq: -1,
  decModal: {
    show: false
  },
  inputData: {
    corpFuri: '',
    corpName: '',
    furiLname: '',
    furiFname: '',
    lastName: '',
    firstName: '',
    birth: '',
    eraCode: '',
    ccode: '',
    mobile: '',
    phone: '',
    zipCode: '',
    addr: '',
    parentFuriLname: '',
    parentFuriFname: '',
    parentLname: '',
    parentFname: '',
    parentBirth: '',
    parentEraCode: '',
    recvZipCode: '',
    recvAddr: '',
    corpSetUpDate: '',
    jobSeq: '',
    cateWrite: '',
    furiForJob: '',
    placeNmForJob: '',
    zipCodeForJob: '',
    addrForJob: '',
    phoneForJob: '',
    isAdult: true
  },
  file: {
    faceImgFile: {
      name: ''
    },
    pfaceImgFile: {
      name: ''
    },
    historyFile: {
      name: ''
    }
  },
  kycFile: {
    idcard1: {
      name: ''
    },
    idcard2: {
      name: ''
    },
    idcard3: {
      name: ''
    },
    idcard4: {
      name: ''
    },
    etc1: {
      name: ''
    },
    pidcard1: {
      name: ''
    },
    pidcard2: {
      name: ''
    },
    pidcard3: {
      name: ''
    },
    pidcard4: {
      name: ''
    },
    etc2: {
      name: ''
    }
  }
};

const state = { ...mainState };

const NewMbrState = (set, get) => ({
  ...state,
  setModalControl(key, value, modalType, addrType) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value,
          type: addrType
        }
      };
    });
  },
  setCommonData(key, value) {
    set((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  },
  reset() {
    set(() => {
      return {
        ...mainState,
        storageKey: 'llpjoin',
        currentUuid: '',
        isOisDo: false,
        isLlpDo: false,
        selectUserCate: '',
        oisAgreeSeq: -1,
        decModal: {
          show: false
        },
        inputData: {
          corpFuri: '',
          corpName: '',
          furiLname: '',
          furiFname: '',
          lastName: '',
          firstName: '',
          birth: '',
          eraCode: '',
          ccode: '',
          mobile: '',
          phone: '',
          zipCode: '',
          addr: '',
          parentFuriLname: '',
          parentFuriFname: '',
          parentLname: '',
          parentFname: '',
          parentBirth: '',
          parentEraCode: '',
          recvZipCode: '',
          recvAddr: '',
          corpSetUpDate: '',
          jobSeq: '',
          cateWrite: '',
          furiForJob: '',
          placeNmForJob: '',
          zipCodeForJob: '',
          addrForJob: '',
          phoneForJob: '',
          isAdult: true
        },
        file: {
          faceImgFile: {
            name: ''
          },
          pfaceImgFile: {
            name: ''
          },
          historyFile: {
            name: ''
          }
        },
        kycFile: {
          idcard1: {
            name: ''
          },
          idcard2: {
            name: ''
          },
          idcard3: {
            name: ''
          },
          idcard4: {
            name: ''
          },
          etc1: {
            name: ''
          },
          pidcard1: {
            name: ''
          },
          pidcard2: {
            name: ''
          },
          pidcard3: {
            name: ''
          },
          pidcard4: {
            name: ''
          },
          etc2: {
            name: ''
          }
        }
      };
    });
  }
});

const NewMbrStore = create(createStore(NewMbrState));

export default NewMbrStore;
