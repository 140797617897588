import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const state = {
  allotApplyList: []
};

const AllotState = (set, get) => ({
  ...state,
  setModalControl(key, value, modalType, addrType) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value,
          type: addrType
        }
      };
    });
  },
  setCommonData(key, value) {
    set((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  }
});

const AllotStore = create(createStore(AllotState));

export default AllotStore;
