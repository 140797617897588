import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const state = {
  replyInfoList: {
    user: {
      nonUserSeq: '',
      userUuid: '',
      nonUserCate: '',
      userCode: '',
      lastName: '',
      firstName: '',
      furiLname: '',
      furiFname: '',
      enLname: '',
      enFname: '',
      userType: '',
      userCate: '',
      gender: '',
      birth: '',
      eraCode: '',
      mobile: '',
      phone: '',
      email: '',
      lineId: ''
    },
    detail: {
      replySeq: '',
      impoLvl: '',
      title: '',
      mngName: '',
      replyWay: '',
      workCase: '',
      closeDate: '',
      roomDesc: '',
      replyBody: '',
      filePath: '',
      filePname: '',
      fileLname: '',
      state: '',
      mdfyDate: '',
      mdfyDp: '',
      mdfyNamePos: '',
      file: { name: '' }
    },
    searchList: [
      {
        furigana: '',
        gender: '',
        name: '',
        mobile: '',
        birth: '',
        userType: '',
        userCode: '',
        userSeq: ''
      }
    ]
  },
  selectedUserData: {
    nonUserSeq: '',
    userUuid: '',
    userCode: '',
    lastName: '',
    firstName: '',
    furiLname: '',
    furiFname: '',
    enLname: '',
    enFname: '',
    userType: '',
    userCate: '',
    gender: '',
    birth: '',
    eraCode: '',
    mobile: '',
    phone: '',
    email: '',
    lineId: ''
  },
  selectedButtonIndex: null,
  pageType: {
    list: ['manage', 'visit'],
    select: 'manage'
  },
  newSearchModal: {
    show: false
  }
};

const VisitState = (set, get) => ({
  ...state,
  setSelectedButtonIndex: (index) => set({ selectedButtonIndex: index }),
  setPage(value) {
    set((state) => {
      return {
        pageType: {
          ...state.pageType,
          select: value
        }
      };
    });
  },
  setModalControl(key, value, modalType, addrType) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value,
          type: addrType
        }
      };
    });
  },
  setCommonData(key, value) {
    set((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  },
  setSelectedUserData(userData) {
    set({ selectedUserData: userData });
  }
});

const VisitStore = create(createStore(VisitState));

export default VisitStore;
