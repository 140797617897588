import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const state = {
  // 현재 등록 상태인지 체크
  // 아닌경우 메인으로 돌아옴
  isBizReg: false,
  currentUuid: '',
  bizTerms: {},
  workTerms: {},
  modalState: ['bizOath', 'bizContract'],
  currentModal: '',
  bmTestSeq: '',
  signModal: false,
  jobData: {}
};

const BizState = (set, get) => ({
  ...state,
  setModalControl(key, value, modalType, addrType) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value,
          type: addrType
        }
      };
    });
  },
  setCommonData(key, value) {
    set((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  },
  nextModal() {
    set((state) => ({
      currentModalIndex: (state.currentModalIndex + 1) % state.modalState.length
    }));
  },
  prevModal() {
    set((state) => ({
      currentModalIndex:
        (state.currentModalIndex - 1 + state.modalState.length) %
        state.modalState.length
    }));
  }
});

const BizStore = create(createStore(BizState));

export default BizStore;
