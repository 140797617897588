import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const state = {
  eventList: [],
  partList: [],
  eventInfoList: {},
  pageType: {
    list: ['list', 'info'],
    select: 'list'
  },
  selectedButtonIndex: null,
};

const EventState = (set, get) => ({
  ...state,
  setSelectedButtonIndex: (index) => set({ selectedButtonIndex: index }),
  setPage(value) {
    set((state) => {
      return {
        pageType: {
          ...state.pageType,
          select: value
        }
      };
    });
  },
  setModalControl(key, value, modalType, addrType) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value,
          type: addrType
        }
      };
    });
  },
  setCommonData(key, value) {
    set((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  }
});

const EventStore = create(createStore(EventState));

export default EventStore;
