import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const state = {
  userResultList: [],
  selectedButtonIndex: null,
  // 회원정보 페이지 타입 설정
  // basic = 기본정보 , agent = 대리인 , dist = 분배금 , verify = 신원검증 , dic = 증명서류
  pageType: {
    list: ['basic', 'agent', 'share', 'dist', 'verify'],
    select: 'basic'
  },
  loginQrModal: {
    show: false
  },
  familyRsModal: {
    show: false
  },
  groupListModal: {
    show: false
  },
  AgentPlusModal: {
    show: false
  },
  dotInquiryAlert: {
    show: false
  },
  dotResultModal: {
    show: false
  },
  distModal: {
    show: false
  },
  decSelectModal: {
    show: false
  },
  countryList: [],
  // 기본정보
  isAdult: true,
  refresh: '',
  mbDataInfoList: {
    userCode: '',
    groupName: '',
    upperName: '',
    familyCnt: '',
    userDeposit: '',
    marginAmt: '',
    agentName: '',
    joinDate: '',
    dotAmt: '',
    qrUrl: ''
  },
  indInfoList: {
    userCate: '',
    userType: '',
    userCode: '',
    gender: '',
    birth: '',
    eraCode: '',
    lname: '',
    fname: '',
    furiLname: '',
    furiFname: '',
    enLname: '',
    enFname: '',
    phone: '',
    mobile: '',
    lineId: '',
    email: '',
    zipCode: '',
    addr: '',
    ccode: '',
    countryName: '',
    filePath: '',
    fileLname: '',
    fileUrl: '',
    mdfyDate: '',
    mdfyName: '',
    faceFile: {
      name: ''
    }
  },
  workInfoList: {
    mdfyDate: '',
    mdfyName: '',
    jobCate: '',
    jobCateWrite: '',
    jobType: '',
    jobTypeWrite: '',
    placeNm: '',
    furi: '',
    zipCode: '',
    addr: '',
    phone: ''
  },
  sendInfoList: {
    mdfyDate: '',
    mdfyName: '',
    state: '',
    newsletter: '',
    zipCode: '',
    addr: ''
  },
  accInfoList: {
    mdfyDate: '',
    bkName: '',
    bkArea: '',
    bkCode: '',
    noType: '',
    bkOwner: '',
    bkNum: ''
  },
  oisInfoList: {
    mdfyDate: '',
    mdfyName: '',
    userId: '',
    userState: '',
    joinDate: ''
  },
  walletInfoList: {
    mdfyDate: '',
    mdfyName: '',
    qrUrl: '',
    walletId: '',
    walletAddr: '',
    state: ''
  },
  signInfoList: {
    mdfyDate: '',
    mdfyName: '',
    rgstDate: '',
    filePath: '',
    filePname: '',
    fileLname: '',
    filePname2: '',
    fileLname2: '',
    fileUrl: '',
    fileUrl2: ''
  },
  // 대리인
  prInfoList: {
    furiLname: '',
    furiFname: '',
    lname: '',
    fname: '',
    enLname: '',
    enFname: '',
    birth: '',
    gender: '',
    eraCode: '',
    countrySeq: '',
    ccode: '',
    countryName: '',
    mobile: '',
    phone: '',
    email: '',
    lineId: '',
    zipCode: '',
    addr: '',
    jobSeq: '',
    secSeq: '',
    jobCate: '',
    jobType: '',
    placeNm: '',
    placeFuri: '',
    jobZipCode: '',
    jobAddr: '',
    jobPhone: '',
    faceFile: {
      name: ''
    }
  },
  reAgentInfoList: [],
  agentInfoList: {
    userAgentSeq: '',
    chkMain: '',
    lname: '',
    fname: '',
    furiLname: '',
    furiFname: '',
    email: '',
    mobile: '',
    phone: '',
    lineId: '',
    zipCode: '',
    addr: '',
    filePath: '',
    filePname: '',
    fileLname: '',
    fileUrl: '',
    agentState: '',
    mdfyDate: '',
    mdfyName: '',
    targetUserUuid: '',
    applyFile: {
      name: ''
    }
  },
  // 분배금
  csInfoList: {
    overDeposit: '',
    fundDeposit: '',
    userDeposit: '',
    needDeposit: ''
  },
  hxInfoList: [
    {
      depositSeq: '',
      inputDate: '',
      outputDate: '',
      sendType: '',
      inputGubun: '',
      depositAmt: '',
      fundCode: '',
      fundName: ''
    }
  ],
  // 신원검증
  kycResultList: {
    ownExpire: null, // 본인, 대표자 신분증 만료 기한
    parentalExpire: null, // 친권자 신분증 만료 기한
    ownState: null, // 본인, 대표자 승인 상태 Y/N/D-심사중
    parentalState: null, // 친권자 심사 상태 Y/N/D
    mdfyDate: null,
    mdfyId: null
  },
  kycFileList: {},
  prIdResultList: {
    kycSeq: '',
    kycState: '',
    filePath: '',
    kycFileSeq1: '',
    filePname1: '',
    fileLname1: '',
    kycFileSeq2: '',
    filePname2: '',
    fileLname2: '',
    kycFileSeq3: '',
    filePname3: '',
    fileLname3: '',
    kycFileSeq4: '',
    filePname4: '',
    fileLname4: '',
    kycFileSeq5: '',
    filePname5: '',
    fileLname5: '',
    mdfyDate: '',
    mdfyName: ''
  },
  vertifyList: {
    kycSeq: '',
    vertifyMemo: '',
    antiState: '',
    kycState: '',
    creditState: '',
    newState: '',
    finalState: '',
    parentAntiState: '',
    parentCreditState: '',
    mdfyName: '',
    mdfyDate: ''
  },
  // 증명서류
  idCardList: {},
  prIdCardList: {},
  familyDocList: {},
  sealVerifyList: {},
  // 법인 이력사항 전부 증명서
  corpResumeList: {
    proofSeq: '',
    proofCate: '',
    expireDate: '',
    filePath: '',
    fileSeq1: '',
    filePname1: '',
    fileLname1: '',
    mdfyDate: '',
    mdfyName: '',
    corpHistoryFile: { name: '' }
  },
  // 실질적지배자가 될 사람 리스트
  factoRulerList: [
    {
      loadSeq: '',
      idx: '',
      rgstDate: '',
      furiLname: '',
      furiFname: '',
      lname: '',
      fname: '',
      enLname: '',
      enFname: '',
      gender: '',
      countrySeq: '',
      ccode: '',
      countryName: '',
      birth: '',
      eraCode: '',
      zipCode: '',
      addr: '',
      violence: '',
      pep: '',
      choice: '',
      relation: '',
      reasonSeq: '',
      reasonDesc: '',
      mdfyDate: '',
      mdfyName: ''
    }
  ],
  corpOwner: [
    {
      furiL: '',
      furiF: '',
      nameL: '',
      nameF: '',
      enL: '',
      enF: '',
      birth: '',
      eraCode: '',
      gender: '',
      ccode: '',
      zipCode: '',
      addr: '',
      gang: '',
      pep: '',
      choice: '',
      relation: '',
      reasonSeq: '',
      idx: '1'
    },
    {
      furiL: '',
      furiF: '',
      nameL: '',
      nameF: '',
      enL: '',
      enF: '',
      birth: '',
      eraCode: '',
      gender: '',
      ccode: '',
      zipCode: '',
      addr: '',
      gang: '',
      pep: '',
      choice: '',
      relation: '',
      reasonSeq: '',
      idx: '2'
    }
  ]
};

const UserState = (set, get) => ({
  ...state,
  setSelectedButtonIndex: (index) => set({ selectedButtonIndex: index }),
  setPage(value) {
    set((state) => {
      return {
        pageType: {
          ...state.pageType,
          select: value
        }
      };
    });
  },
  setCountryList(value) {
    set(() => ({
      countryList: value
    }));
  },
  setModalControl(key, value, modalType, data) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value,
          ...data
        }
      };
    });
  },
  setCommonData(key, value) {
    set((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  }
});

const UserStore = create(createStore(UserState));

export default UserStore;
