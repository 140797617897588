import axios from 'axios';
import AesDecrypt from './AesDecrypt';
import AesEncrypt from './AesEncrypt';

import commonStore from '@/store/commonStore';
const { language, setIsLogin, setUserInfo, setAdminInfo, setCommonData } =
  commonStore();

const LOCALSTORAGE_USERINFO_KEY = 'llpt';

const baseUrl = (type) => {
  const option = process.env.VUE_APP_DEV_OPTION;
  let url;
  if (type === 'llptab') {
    switch (option) {
      case 'release':
        url = process.env.VUE_APP_LLP_API_URI_REAL;
        break;
      case 'test':
        url = process.env.VUE_APP_LLP_API_URI_TEST;
        break;
      case 'dev':
        url = process.env.VUE_APP_LLP_API_URI_DEV;
        break;
    }
  } else {
    switch (option) {
      case 'release':
        url = process.env.VUE_APP_OIS_API_URI_REAL;
        break;
      case 'test':
        url = process.env.VUE_APP_OIS_API_URI_TEST;
        break;
      case 'dev':
        url = process.env.VUE_APP_OIS_API_URI_DEV;
        break;
    }
  }
  return url;
};

const Request = async (uri, method, reqData, forms, headers) => {
  const userInfo = localStorage.getItem(LOCALSTORAGE_USERINFO_KEY);
  const reqType = uri.split('/')[1];
  const chkRefresh = '/llptab/api/com/v1/request_country_list';
  let token;
  let rtoken;

  if (userInfo !== null) {
    const { accessToken, refreshToken } = JSON.parse(userInfo);

    (token = accessToken), (rtoken = refreshToken);
  }
  try {
    let res = await axios({
      method: method,
      url: `${uri}`,
      headers: {
        'Content-Type': forms ? 'multipart/form-data' : 'application/json',
        language: language.value || 'en',
        apikey: process.env.VUE_APP_MAIN_API_KEY,
        Authorization: `Bearer ${token}`,
        Validation: `Bearer ${rtoken}`,
        ...headers
      },
      data: forms ? reqData : reqData || {}
    });

    if (res.status !== 200) return false;
    let resData = res.data;

    // 로그인 세션 만료 처리
    if (resData.API_CODE.status === 'L402') {
      localStorage.removeItem(LOCALSTORAGE_USERINFO_KEY);
      setIsLogin.value(false);
      setCommonData.value('goLoginPage', true);
      return false;
    } else if (resData.API_CODE.status === '0000') {
      if (resData.USER !== undefined) {
        setUserInfo.value(resData.USER);
      }
      if (resData.ADMIN !== undefined) {
        setAdminInfo.value(resData.ADMIN);
      }
      if (resData.AUTH !== undefined) {
        const accessToken = resData.AUTH.token;
        const refreshToken = resData.AUTH.rtoken;
        localStorage.setItem(
          LOCALSTORAGE_USERINFO_KEY,
          JSON.stringify({ accessToken, refreshToken })
        );
      }
      return resData;
    } else {
      setCommonData.value('errMsg', resData.API_CODE);
      setCommonData.value('errState', true);

      return resData;
    }
  } catch (e) {
    console.log(`mainapi catch => ${e}`);
    return false;
  }
};

export default Request;
