import CommonStore from './commonStore';
import LoginStore from '@/feature/login/store/LoginStore';
import UserStore from '@/feature/user/store/UserStore';
import AllotStore from '@/feature/allot/store/AllotStore';
import VisitStore from '@/feature/visit/store/VisitStore';
import EventStore from '@/feature/event/store/EventStore';
import NoMbrStore from '@/feature/noUser/store/NoMbrStore';
import NewMbrStore from '@/feature/newUser/store/NewMbrStore';
import BizStore from '@/feature/biz/store/BizStore';

export const rootStore = {
  commonStore: CommonStore,
  loginStore: LoginStore,
  userStore: UserStore,
  allotStore: AllotStore,
  visitStore: VisitStore,
  eventStore: EventStore,
  noMbrStore: NoMbrStore,
  newMbrStore: NewMbrStore,
  bizStore: BizStore
};
