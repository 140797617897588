import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const state = {
  loginInputData: {
    id: '',
    pw: ''
  }
};

const LoginState = (set, get) => ({
  ...state,
  setModalControl(key, value) {
    set((state) => {
      return {
        loginModal: {
          ...state.loginModal,
          [key]: value
        }
      };
    });
  },
  handleInputId(value) {
    set((state) => {
      return {
        loginInputData: {
          ...state.loginInputData,
          id: value
        }
      };
    });
  },
  setErrorAlertData(title, body) {
    set((state) => {
      return {
        errorAlertData: {
          title: title,
          body: body
        }
      };
    });
  },
  resetLoginInput() {
    set((state) => {
      return {
        loginInputData: {
          id: '',
          pw: ''
        }
      };
    });
  },
  setCommonData(key, value) {
    set(() => {
      return {
        [key]: value
      };
    });
  }
});

const LoginStore = create(createStore(LoginState));

export default LoginStore;
